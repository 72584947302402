.containerTable {
  width: 100%;
  height: 100%;
  position: relative;
}
.container-empty {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  text-align: center;
}

.container-empty .content {
  font-size: 16px;
}
.container-empty .legend {
  font-size: 16px;
  font-weight: bold;
  margin: 4px 0 !important;
}

.container-empty .title {
  font-size: 20px;
  font-weight: 700;
  margin: 0;
}

.width-small {
  width: 405px;
}
.row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.is-relative {
  position: relative;
}
.is-flex {
  display: flex;
}
.is-align-items-center {
  align-items: center;
}
.is-align-items-start {
  align-items: flex-start;
}
.is-justify-content-center {
  justify-content: center;
}
.is-justify-content-end {
  justify-content: flex-end;
}
.is-justify-content-start {
  justify-content: flex-start;
}
.is-flex-1 {
  flex: 1;
}
.is-full-height {
  height: 100%;
}
.is-full-width {
  width: 100%;
}
.is-overflow-auto {
  overflow: auto;
}
.is-flex-direction-column {
  flex-direction: column;
}
.is-flex-wrap-wrap {
  flex-wrap: wrap;
}
.row .icon-check2 {
  color: #1abc9c;
  margin-right: 10px;
}
.color-purple {
  color: #6951CE;
}

.fontSize-small {
  font-size: 13px;
}
.pl-1 {
  margin-left: 5px;
}
.box-sizing-border-box {
  box-sizing: border-box;
}
.p-1 {
  padding: 1rem;
}
.p-2 {
  padding: 2rem;
}
.p-5 {
  padding: 5rem;
}
.mb-2 {
  margin-bottom: 10px;
}
.mt-1 {
  margin-top: 5px;
}
.m-0 {
  margin: 0px;
}
.outline-none {
  outline: none;
}
.history .datePickerInput {
  width: 100%;
}
.history .datePickerInput input {
  box-sizing: border-box;
}

.row.history .datePickerInput .react-datepicker__navigation {
  color: white;
}
.row.history .datePickerInput .react-datepicker__navigation.react-datepicker__navigation--next:before,
.row.history .datePickerInput .react-datepicker__navigation.react-datepicker__navigation--previous:before  {
  border-color: #ccc;
  border-style: solid;
  border-width: 3px 3px 0 0;
  content: "";
  display: block;
  height: 9px;
  position: absolute;
  top: 6px;
  width: 9px;
}
.row.history .datePickerInput .react-datepicker__navigation.react-datepicker__navigation--previous:before {
  left: 20px;
  transform: rotate(225deg);
}
.row.history .datePickerInput .react-datepicker__navigation.react-datepicker__navigation--next:before {
  transform: rotate(45deg);
  left: -7px;
}


.detail-site {
  word-break: break-word;
}


@font-face {
  font-family: 'icomoon';
  src:  url('https://cdn.icomoon.io/146409/Iconoscliengo/style.css?srk8u3');
  src:  url('https://cdn.icomoon.io/146409/Iconoscliengo/icomoon.eot?srk8u3#iefix') format('embedded-opentype'),
    url('https://cdn.icomoon.io/146409/Iconoscliengo/icomoon.woff2?srk8u3') format('woff2'),
    url('https://cdn.icomoon.io/146409/Iconoscliengo/icomoon.ttf?srk8u3') format('truetype'),
    url('https://cdn.icomoon.io/146409/Iconoscliengo/icomoon.woff?srk8u3') format('woff'),
    url('https://cdn.icomoon.io/146409/Iconoscliengo/icomoon.svg?srk8u3#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
.icn-smileys_people {
  background-image:none !important;
}
.icn-animals_nature {
  background-image:none !important;
}
.icn-travel_places {
  background-image:none !important;
}
.icn-activities {
  background-image:none !important;
}
.icn-objects {
  background-image:none !important;
}
.icn-symbols {
  background-image:none !important;
}
.icn-smileys_people:before {

  content: '\ea07';
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icn-animals_nature:before {

  content: '\ea23';
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icn-travel_places:before {

  content: '\e916';
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icn-activities:before {

  content: '\ea27';
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icn-objects:before {

  content: '\e9ef';
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icn-symbols:before {

  content: '\e91f';
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mobile-icon {
  display: none;
}

@media only screen and (max-width: 600px) {
  .container-empty {
    text-align: center;
  }
  .row {
    justify-content: center;
  }
  .width-small {
    width: 300px;
  }

  .history .is-full-height {
    height: calc(100% - 50px);
  }

  .mobile-icon {
    display: inline;
  }
}

.contextConversationModalContainer [class^="InterveneConversation__StyledModal"],
.contextConversationModalContainer [class*=" InterveneConversation__StyledModal"] {
  max-width: 420px;
  z-index: 999;
}
